export default function WaitSpinner({ dark }) {
  return (
    <div className="  text-center py-4">
      <div
        style={{ width: "150px", height: "150px" }}
        className={`spinner-border ${dark ? " text-success " : " text-danger"}`}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
      <p className={`mb-0 mt-2   ${dark ? "text-white" : ""}`}>
        We are on it...
      </p>
    </div>
  );
}
