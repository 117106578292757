import { useDispatch, useSelector } from "react-redux";
import alert from "../../store/reducers/alert-reducer";
import { useEffect } from "react";
const Alert = ({ sticky, fixed }) => {
  const { heading, message, type, detail } = useSelector(
    (store) => store.alert
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // setTimeout(() => {
    //   dispatch(alert.clear());
    // }, 15000);
  });
  let alertStyle;
  switch (type) {
    case "success":
      alertStyle = "rgb(29 130 72)";
      break;
    case "danger":
      alertStyle = "#dc3c4b";
      break;
    default:
      alertStyle = "#4a93e0";
      break;
  }
  return heading ? (
    <div
      className={`mx-auto shadow custom_alert  ${
        sticky ? "sticky" : fixed ? "fixed" : ""
      }`}
    >
      <h6
        className="display-6 m-0"
        style={{
          backgroundColor: alertStyle,
          color: "white",
          padding: "2px 20px",
          fontSize: "1.5rem",
        }}
      >
        {heading}
        <span
          onClick={() => {
            dispatch(alert.clear());
          }}
          className={`fa fa-times close_btn`}
        ></span>
      </h6>
      <p
        style={{
          padding: "10px 20px",
          backgroundColor: "#f5f5f5",
          color: "#444444",
        }}
        className="m-0"
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
      {detail ? <div dangerouslySetInnerHTML={{ __html: detail }}></div> : ""}
    </div>
  ) : (
    <></>
  );
};

export default Alert;
