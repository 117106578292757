import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import xIcon from "../images/xicon.webp";
import validate from "../functions/validations/bookings";
import http from "../functions/http";
import Spinner from "./components/spinner";
import { useDispatch } from "react-redux";
import alert from "../store/reducers/alert-reducer";

const ContactUs = () => {
  let dispatch = useDispatch();
  const [submitting, set_submitting] = useState(false);

  const nameInput = useRef();
  const emailInput = useRef();
  const messageInput = useRef();

  const [nameErr, set_nameErr] = useState("");
  const [emailErr, set_emailErr] = useState("");
  const [messageErr, set_messageErr] = useState("");

  useEffect(() => {
    document.title = "Contact Delhi Tandoori: Reach Out Now";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "Have questions or feedback? Contact Delhi Tandoori today for a delicious journey. Reach out now for a flavourful experience."
      );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearErrs();
    if (submitting) return;
    set_submitting(true);

    let err = null;

    const name = validate._name(nameInput.current.value);
    const email = validate._email(emailInput.current.value);
    const message = validate._message(messageInput.current.value);

    if (name.err) {
      err = 1;
      set_nameErr(name.err);
    }
    if (email.err) {
      err = 1;
      set_emailErr(email.err);
    }
    if (message.err) {
      err = 1;
      set_messageErr(message.err);
    }
    if (err) {
      set_submitting(false);
      return;
    }
    const dataToSubmit = { name, email, message };
    let res = await http.post("/api/contact", dataToSubmit);

    res = res.data;
    if (res && res.id) {
      set_submitting(false);
      dispatch(
        alert.load({
          message: `Your message has been sent.`,
          heading: "Thank You!",
          type: "success",
        })
      );
      clearValues();
    }
  };

  function clearErrs() {
    set_nameErr("");
    set_emailErr("");
    set_messageErr("");
  }
  // clear values
  function clearValues() {
    nameInput.current.value = "";
    emailInput.current.value = "";
    messageInput.current.value = "";
  }
  return (
    <>
      <Helmet>
        <title>Contact Delhi Tandoori: Reach Out Now</title>
        <meta
          name="description"
          content="Have questions or feedback? Contact Delhi Tandoori today for a delicious journey. Reach out now for a flavourful experience."
        />
      </Helmet>
      {/* banner */}
      <div
        className="banner-height-2  contact-us-banner"
        style={{
          position: "relative",
        }}
      >
        <div className="h-100 d-flex flex-wrap flex-column justify-content-center align-items-center">
          <h1
            style={{ backgroundColor: "#000000b0" }}
            className=" text-white  mt-5  w-100 text-center   fs-lg-1 fs-2  py-1  fw-light  "
          >
            CONTACT US
          </h1>
        </div>
      </div>

      {/* section-1 */}
      <section className="contact-us-section-1 py-5">
        <div style={{ fontFamily: "serif" }} className="container">
          <div className="row">
            <div className="mb-5 col-lg-6">
              <h2 className="fs-4" style={{ fontFamily: "serif" }}>
                Contact
              </h2>
              <div className="fw-normal">
                We're here to make your experience
                <br /> with Delhi Tandoori remarkable.
                <br /> Reach out to us for any inquiries or feedback.
              </div>
            </div>
            <div className="mb-5 col-lg-6">
              <h2 className="fs-4">
                <span className="title align-middle d-inline-block me-1 text-end">
                  Tel:
                </span>{" "}
                <a
                  className="text-secondary fs-6 fs-lg-5 d-inline-block align-middle"
                  href="tel:+4722201248"
                >
                  +47 22 20 12 48
                </a>
              </h2>

              <h2 className="fs-4">
                <span className="title align-middle d-inline-block me-1 text-end">
                  Email:
                </span>{" "}
                <a
                  className="text-secondary fs-6 fs-lg-5 d-inline-block align-middle"
                  href="mailto:info@delhitandoori.restaurant"
                >
                  info@rdtandoori.com
                </a>
              </h2>
            </div>
            <div className="mb-5 col-lg-6">
              <h2 className="fs-4">Follow Us</h2>
              <ul
                className="list-unstyled d-flex social-icons mb-0  "
                style={{ fontSize: "20px", marginLeft: "-8px" }}
              >
                <li>
                  <a
                    title="facebook"
                    className="px-2 me-1"
                    href="https://www.facebook.com/Delhi.tandoori76"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a
                    title="instagram"
                    className="px-2 me-1"
                    href="https://www.instagram.com/delhi.tandoori?igsh=ZTI2dWQ4NDg5Z3N1"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    title="twitter"
                    className="px-2 me-1"
                    href="https://x.com/Delhi_Tandoori?t=N_92rvc7s3fSedy0F33aCg&s=09"
                  >
                    <img
                      src={xIcon}
                      width={17}
                      height={17}
                      style={{ marginBottom: "5px" }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    title="linked in"
                    className="px-2 me-1"
                    href="https://www.linkedin.com/company/delhi-tandoori-as/"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                {/* <li>
                  <a title="tik tok" className="px-2 me-1" href="/">
                    <i className="fab fa-tiktok"></i>
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-6">
              <h2 className="fs-4">Opening Hours</h2>
              <h6 className="fw-normal">Monday To Sunday</h6>
              <div>03:00 PM - 10:00 PM</div>
            </div>
          </div>
        </div>
      </section>

      {/* section-2 */}
      <section>
        <div className="container">
          <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1999.7517422824233!2d10.746318499252755!3d59.91966739892697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e64469baa1b%3A0xc56c53e8f3c27d94!2sDelhi%20Tandoori!5e0!3m2!1sen!2s!4v1712176429859!5m2!1sen!2s"
            width="100%"
            height="400px"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      {/* section-3 */}
      <section className="my-4">
        <div
          style={{
            backgroundImage: "linear-gradient(90deg,rgb(88 94 94), rgb(0 0 0))",
          }}
          className="container  text-white "
        >
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="p-3 p-md-5">
                <h2 className="mb-3" style={{ fontFamily: "serif" }}>
                  Get in touch
                </h2>
                <div className="fs-5 fw-light" style={{ fontFamily: "gothic" }}>
                  If you have questions or comments, please get a hold of us in
                  whichever way is most convenient. Ask away. There is no
                  reasonable question that our team can not answer.
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <form onSubmit={handleSubmit} className="p-3 p-md-5">
                <div className="mb-4 pb-1 ">
                  <label
                    style={{ fontFamily: "serif" }}
                    className="fs-5"
                    htmlFor="contact-us-name"
                  >
                    Your Name
                  </label>
                  <input
                    readOnly={submitting}
                    ref={nameInput}
                    onChange={() => {
                      set_nameErr("");
                    }}
                    style={{ backgroundColor: "unset", color: "white" }}
                    type="text"
                    className="form-control fs-5"
                  />
                  <div style={{ color: "pink" }}>
                    {nameErr ? (
                      <span className="p-1 d-block">{nameErr}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-4 pb-1 ">
                  <label
                    style={{ fontFamily: "serif" }}
                    className="fs-5"
                    htmlFor="contact-us-name"
                  >
                    Your Email
                  </label>
                  <input
                    readOnly={submitting}
                    onChange={() => {
                      set_emailErr("");
                    }}
                    ref={emailInput}
                    style={{ backgroundColor: "unset", color: "white" }}
                    type="text"
                    className="form-control fs-5"
                  />
                  <div style={{ color: "pink" }}>
                    {emailErr ? (
                      <span className="p-1 d-block">{emailErr}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="mb-4 pb-1 ">
                  <label
                    style={{ fontFamily: "serif" }}
                    className="fs-5"
                    htmlFor="contact-us-name"
                  >
                    Your Message
                  </label>
                  <textarea
                    readOnly={submitting}
                    onChange={() => {
                      set_messageErr("");
                    }}
                    ref={messageInput}
                    rows={4}
                    style={{ backgroundColor: "unset", color: "white" }}
                    className="form-control fs-5"
                    maxLength={1000}
                  ></textarea>
                  <div style={{ color: "pink" }}>
                    {messageErr ? (
                      <span className="p-1 d-block">{messageErr}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mb-2">
                  {submitting ? (
                    <div className="bg-light btn" style={{ minWidth: "180px" }}>
                      <Spinner color={"#f24141"} text={"sending..."} />
                    </div>
                  ) : (
                    <button
                      className="btn btn-light  "
                      style={{ minWidth: "180px" }}
                    >
                      SUBMIT
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
