function checkDate(value) {
  if (new Date(value).toString() === "Invalid Date") {
    return false;
  }
  return true;
}
const _name = (value = "") => {
  let err = null;
  if (value === "") {
    err = "required!";
  } else if (value.length > 255) {
    err = "must not exceed 255 characters!";
  } else if (!/^[a-zA-Z-0-9' ]*$/.test(value)) {
    err = "characters other than - or ' are not allowed!";
  }

  return err ? { err } : value.trim();
};

const _email = (value = "") => {
  let err = null;
  if (value === "") {
    err = "required!";
  } else if (value.length > 100) {
    err = "must not exceed 100 characters!";
  } else if (
    !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(value)
  ) {
    err = "email is not valid!";
  }

  return err ? { err } : value.trim();
};

const _phone = (value = "") => {
  let err = null;
  if (value === "") {
    err = "required!";
  } else if (value.length > 20) {
    err = "must not exceed 20 characters!";
  } else if (!/^\+?\d+$/.test(value)) {
    err = "phone is not valid!";
  }

  return err ? { err } : value.trim();
};

const _description = (value = "") => {
  let err = null;
  if (value.length > 1000) {
    err = "must not exceed 1000 characters!";
  }

  return err ? { err } : value.trim();
};
const _message = (value = "") => {
  let err = null;
  if (value === "") {
    err = "required!";
  } else if (value.length > 1000) {
    err = "must not exceed 1000 characters!";
  }

  return err ? { err } : value.trim();
};
const _seats = (value) => {
  let err = null;
  if (value === "") {
    return { err: "required!" };
  }
  value = parseInt(value);
  if (value && value > 20000) {
    err = "must not exceed 20000!";
  } else if (value && value < 0) {
    err = "must not be less than 0!";
  }
  return err ? { err } : isNaN(value) ? 0 : value;
};
const _date = (value, required = 0) => {
  let err = null;
  if (value === "") {
    return { err: "required!" };
  }
  if (value === "" && required) {
    err = "required!";
  } else if (value && !checkDate(value)) {
    err = "not a valid date!";
  }

  return err ? { err } : value;
};
const _time = (value) => {
  let err = null;
  if (value === "") {
    return { err: "required!" };
  }
  if (
    value &&
    !/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
  ) {
    err = "not a valid time!";
  }

  return err ? { err } : value;
};
const validate = {
  _name,
  _email,
  _description,
  _message,
  _seats,
  _phone,
  _date,
  _time,
};
export default validate;
