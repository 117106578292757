import { useEffect } from "react";
import xIcon from "../../images/xicon.webp";
import http from "../../functions/http";
const Header = () => {
  useEffect(() => {
    http.get("/sanctum/csrf-cookie");
  }, []);
  return (
    <header className="container">
      <div className="d-flex flex-wrap align-items-center">
        <ul
          className="list-unstyled social-icons mb-0 py-2 py-lg-1 py-xxl-2"
          style={{ fontSize: "18px" }}
        >
          <li>
            <a
              style={{ color: "#4267B2" }}
              href="https://www.facebook.com/Delhi.tandoori76"
            >
              <i className="fab fa-facebook"></i>
            </a>
          </li>
          <li>
            <a
              style={{ color: "" }}
              href="https://www.instagram.com/delhi.tandoori?igsh=ZTI2dWQ4NDg5Z3N1"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              style={{ color: "#1DA1F2" }}
              href="https://x.com/Delhi_Tandoori?t=N_92rvc7s3fSedy0F33aCg&s=09"
            >
              <img
                className="align-middle"
                src={xIcon}
                width={18}
                height={18}
                style={{ marginBottom: "5px" }}
              />
            </a>
          </li>
          <li>
            <a
              style={{ color: "#0072b1" }}
              href="https://www.linkedin.com/company/delhi-tandoori-as/"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
          {/* <li>
            <a style={{ color: "#e43b3b" }} href="/">
              <i className="fab fa-tiktok"></i>
            </a>
          </li> */}
        </ul>

        <div
          className="fs-xxl-6 ms-auto header-happy-hour"
          style={{ fontFamily: "arimo", fontSize: "12px" }}
        >
          <div className="ms-1 d-inline-flex align-content-center align-items-center">
            <div className="d-flex align-items-center">
              <i className="fas fa-glass-martini-alt me-2"></i>{" "}
              <div>HAPPY HOUR</div>{" "}
            </div>
            <div className="mx-2">|</div>
            <div className="text-center">
              16:00 TO 17:00{" "}
              <div className="fst-italic">
                15% OFF on Main Course when dinein.
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
