const { createSlice } = require("@reduxjs/toolkit");

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: window.dat && window.dat.products ? window.dat.products : 0,
  },
  reducers: {
    load: (state, action) => {
      state.products = action.payload;
    },

    clear: (state) => {
      state.products = 0;
    },
  },
});

const products = productsSlice.actions;
export default products;
export const productsReducer = productsSlice.reducer;
