import Logo from "./components/logo";
import logo from "../images/logo2.png";
import bannerImage from "../images/index-banner.webp";
import plate from "../images/home/plate.webp";
import logo3 from "../images/logo3.png";
import image2 from "../images/home/image2.webp";
import pot from "../images/pot-icon1.png";
import glass from "../images/glass-icon1.png";
import coffee from "../images/coffee-icon1.png";
import cake from "../images/cake-icon1.png";
import bgVideo from "../images/bg-video-compressed.webm";

import productsServingsStore from "../store/reducers/products-servings";
import productsStore from "../store/reducers/products";
import categoriesStore from "../store/reducers/categories";
import { useDispatch, useSelector } from "react-redux";

// aos
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import http from "../functions/http";
import ArrowDown from "./components/banner-arrow-down";
import OfferedMenu from "./Index/OfferedMenu";
import { Link } from "react-router-dom";

const Index = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const productsServings = useSelector(
    (state) => state.productsServings.productsServings
  );

  const [productsToShow, set_productsToShow] = useState(
    window.dat && window.dat.products_to_show ? window.dat.products_to_show : []
  );

  const categories = useSelector((state) => state.categories.categories);
  const offeredMenu = useSelector((state) => state.categories.offeredMenu);

  useEffect(() => {
    const getProducts = async () => {
      if (productsServings && products && categories.length > 1) return;
      try {
        const res = await http.get("/api/products?order_by=1&categorically=1");

        if (!products && res.data.products)
          dispatch(productsStore.load(res.data.products));

        if (!productsServings && res.data.products_servings)
          dispatch(productsServingsStore.load(res.data.products_servings));
        if (categories.length < 1 && res.data.categories)
          dispatch(categoriesStore.load(res.data.categories));
      } catch (err) {
        console.log(err);
      }
    };
    // getProducts();
    AOS.init();
  }, [dispatch, categories.length, products, productsServings]);
  const indexRec = useRef();
  useEffect(() => {
    if (offeredMenu.length > 0) {
      set_productsToShow(products[offeredMenu[0].id] || []);
      indexRec.current = {};
      offeredMenu.forEach((v) => {
        indexRec.current[v.id] = { start: 0, end: 6 };
      });
    }
  }, [offeredMenu]);

  const handleMenuTabClick = (id) => {
    set_productsToShow(products[id]);
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.getElementById("navbarSupportedContent").classList.remove("show");
  };

  return (
    <>
      <div
        className="index-banner banner-height"
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <video
            poster={bannerImage}
            loop={true}
            src={bgVideo}
            autoPlay={true}
            muted
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          ></video>
        </div>

        <div className="h-100 d-flex flex-wrap flex-column justify-content-between align-items-center">
          <div style={{ height: "120px" }}></div>
          <h1
            style={{ fontFamily: "serif" }}
            className="index-banner-h1 fs-6 fs-lg-4  fs-xxl-3  fw-light mb-5 mb-md-5"
          >
            <div className="d-inline-block" style={{ marginLeft: "-.5em" }}>
              <span className="align-middle d-inline-block animate__animated animate__bounceInUp">
                {" "}
                SAME FAMILY
              </span>
              <img
                width={388}
                height={424}
                style={{ width: "4.2em", height: "auto" }}
                src={logo}
                className="mx-2 animate__animated animate__heartBeat"
                alt="delhi tandoori"
              />
              <span className="align-middle d-inline-block animate__animated animate__bounceInUp">
                SAME TASTE
              </span>
            </div>
          </h1>

          <div
            style={{
              fontFamily: "poppins",
              position: "relative",
              zIndex: "3",
              backdropFilter: "blur(10px)",
            }}
            className="bg-transparent-black text-2  fw-extralight text-white w-100 p-2 text-center"
          >
            <marquee className="align-middle">
              WELCOME TO DELHI TANDOORI, THE TASTE YOU NEVER IMAGINE BEFORE
            </marquee>
          </div>

          <ArrowDown />
        </div>
      </div>

      {/* section-1 */}
      <section className="overflow-hidden container pt-5 pb-3  mt-3 index-section-1">
        <div className="row">
          <div className="col-lg-8">
            <div data-aos="zoom-in" className="about">
              <h1 style={{ fontSize: "2em" }}>
                <span
                  style={{
                    fontFamily: "cursive",
                    fontWeight: "normal",
                    fontSize: ".5em",
                    fontStyle: "italic",
                    color: "var(--color-2)",
                    marginRight: "3em",
                  }}
                >
                  About
                </span>
                <span
                  style={{ fontFamily: "serif" }}
                  className="fw-semibold text-secondary"
                >
                  DELHI TANDOORI
                </span>
              </h1>
              <div
                style={{
                  fontSize: "3.97em",
                  fontFamily: "courier",
                  color: "var(--color-1)",
                  lineHeight: ".5em",
                }}
              >
                RESTAURANT
              </div>
              <div className="fs-lg-4 fw-light  fs-6 mb-3 mb-lg-4 mt-3">
                Since 1996, Delhi Tandoori has been a taste of India in Oslo.
                Our flavours unite with Norway's heartwarming spirit, crafting
                cherished dining memories. Join us in this culinary journey that
                resonates with 27 years of excellence!
                <div className="text-center">
                  <img
                    style={{ width: "2.5em" }}
                    src={logo3}
                    alt="delhi tandoori"
                    className="mt-lg-3 mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-8 mx-auto">
            <div data-aos="fade-left">
              <img
                src={plate}
                width={"100%"}
                className="w-100"
                alt="delhi tandoori"
              />
            </div>
          </div>
        </div>
      </section>

      {/* section-2 */}
      <div
        style={{ maxWidth: "1980px" }}
        className="overflow-hidden position-relative mx-auto index-section-2"
      >
        <img src={image2} alt="fish" className="w-100" />
        <div className="text-div position-absolute top-50 text-white text-center  translate-middle-y">
          <div data-aos-duration="1500" data-aos="fade-up">
            "EXPERIENCE INDIA'S AUTHENTIC FLAVORS AT DELHI TANDOORI, YOUR TOP
            INDIAN RESTAURANT IN OSLO. INDULGE IN TANDOORI DELIGHTS TODAY!"
          </div>
        </div>

        <div
          style={{
            zIndex: 33,
            fontSize: "2.5vw",
            right: "2em",
            bottom: "1em",
          }}
          className="position-absolute text-white text-center"
        >
          <Logo size={"1em"} />
        </div>
      </div>

      {/* section-3 */}
      <section className="  pt-5 pb-lg-5 pb-3 index-section-3">
        <div className="container">
          <div className="row">
            {/* heading */}
            <div className="col-12 mb-3 mb-lg-5">
              <h1 className="fst-italic section-heading text-center">
                One Place Solution
              </h1>
            </div>

            <div className="col-md-4 col-lg-3 mb-5 col-11 mx-auto mb-lg-0">
              <div className="text-center">
                <img src={pot} style={{ width: "23%" }} alt="pot-icon" />
                <div
                  className="mt-3"
                  style={{
                    width: "200px",
                    margin: "auto",
                    height: "100px",
                    boxSizing: "border-box",
                  }}
                >
                  Book your table at Delhi Tandoori, Oslo's finest Indian
                  restaurant.
                </div>
                <div className="mt-md-3">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#bookATableModal"
                    className="btn btn-outline-secondary rounded-1 shadow  py-1 px-3  "
                  >
                    RESERVATION
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 mb-5 col-11 mx-auto mb-lg-0">
              <div className="text-center">
                <img src={glass} style={{ width: "23%" }} alt="pot-icon" />
                <div
                  className="mt-3"
                  style={{
                    width: "200px",
                    margin: "auto",
                    height: "100px",
                    boxSizing: "border-box",
                  }}
                >
                  Enjoy Delhi Tandoori at home! Order now for flavourful Indian
                  take-away.
                </div>
                <div className="mt-md-3">
                  <Link
                    to="/take-away"
                    onClick={handleLinkClick}
                    className="btn btn-outline-secondary rounded-1 shadow  py-1 px-3  "
                  >
                    TAKE AWAY
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 mb-5 col-11 mx-auto mb-lg-0">
              <div className="text-center">
                <img src={coffee} style={{ width: "23%" }} alt="pot-icon" />
                <div
                  className="mt-3"
                  style={{
                    width: "200px",
                    margin: "auto",
                    height: "100px",
                    boxSizing: "border-box",
                  }}
                >
                  Discover Indian Flavors at Delhi Tandoori From curries to
                  tandoori, each dish has a story.
                </div>
                <div className="mt-3">
                  <Link
                    to="/menu"
                    onClick={handleLinkClick}
                    className="btn btn-outline-secondary rounded-1 shadow  py-1 px-3  "
                  >
                    OUR MENU
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3   col-11 mx-auto  ">
              <div className="text-center">
                <img src={cake} style={{ width: "23%" }} alt="pot-icon" />
                <div
                  className="mt-3"
                  style={{
                    width: "200px",
                    margin: "auto",
                    height: "100px",
                    boxSizing: "border-box",
                  }}
                >
                  Discover why Delhi Tandoori is loved by Oslo. Read our
                  customer reviews and share your own experience.
                </div>
                <div className="mt-3">
                  <button className="btn btn-outline-secondary rounded-1 shadow  py-1 px-3  ">
                    OUR REVIEWS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section-4 */}
      <section className="overflow-hidden index-section-4 pt-5 pb-lg-5 pb-3 my-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div>
                <div
                  data-aos="flip-right"
                  className="position-relative mb-4 w-100 card-bg-1"
                >
                  <div style={{ width: "100%", paddingTop: "90%" }}>
                    <div
                      style={{ zIndex: "3" }}
                      className="position-absolute d-flex align-items-center justify-content-center top-0 start-0 h-100 w-100"
                    >
                      <h1
                        style={{ fontFamily: "poppins" }}
                        className="text-white fw-extralight text-center"
                      >
                        <i
                          style={{ fontSize: "1.5em" }}
                          className="fas fa-glass-martini-alt"
                        ></i>
                        <div
                          style={{ fontFamily: "serif" }}
                          className="fst-italic"
                        >
                          15% Discount
                        </div>
                        <div>HAPPY HOUR</div>
                        <div className="fw-light fs-5 text-decoration-underline">
                          16:00 To 17:00
                        </div>
                        <div className="fw-light fs-4">Monday To Friday</div>
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="position-relative mb-4 mb-lg-0 w-100 bg-light-grey">
                  <div style={{ width: "100%", paddingTop: "90%" }}>
                    <div
                      style={{ zIndex: "3" }}
                      className="position-absolute d-flex align-items-center justify-content-center top-0 start-0 h-100 w-100"
                    >
                      <h1
                        style={{ fontFamily: "poppins" }}
                        className="text-center"
                      >
                        <div className="fw-light fs-6">WHAT'S HAPPENING</div>
                        <div className="fs-2" style={{ fontFamily: "serif" }}>
                          Latest News, Stories And <br /> Recipes
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mb-4 mb-lg-0">
              <div
                data-aos-duration="1500"
                data-aos="fade-up"
                className="position-relative h-100 w-100  card-bg-2"
              >
                <div style={{ width: "100%", paddingTop: "100%" }}>
                  <div
                    style={{ zIndex: "3" }}
                    className="position-absolute d-flex align-items-center justify-content-center top-0 start-0 h-100 w-100"
                  >
                    <h1
                      style={{ fontFamily: "poppins" }}
                      className="text-white fw-extralight text-center"
                    >
                      <div className="fw-light fs-6">
                        THE SOUL KITCHEN EXPERIENCE
                      </div>
                      <div
                        style={{ fontFamily: "serif" }}
                        className="fst-italic"
                      >
                        FULL-SERVING <br />
                        CATERING
                      </div>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <div
                  data-aos="fade-left"
                  className="position-relative mb-4  w-100 bg-light-grey"
                >
                  <div style={{ width: "100%", paddingTop: "90%" }}>
                    <div
                      style={{ zIndex: "3" }}
                      className="position-absolute d-flex align-items-center justify-content-center top-0 start-0 h-100 w-100"
                    >
                      <h1
                        style={{ fontFamily: "poppins" }}
                        className="text-center"
                      >
                        <div className="fw-light fs-6">
                          THE PERFECT GIFT FOR A LOVED ONE
                        </div>
                        <div style={{ fontFamily: "serif" }}>
                          Soul Kitchen <br /> Gift Card
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  data-aos-duration="1000"
                  data-aos="flip-left"
                  className="position-relative mb-4 mb-lg-0 w-100  card-bg-3"
                >
                  <div style={{ width: "100%", paddingTop: "90%" }}>
                    <div
                      style={{ zIndex: "3" }}
                      className="position-absolute d-flex align-items-end justify-content-center top-0 start-0 h-100 w-100"
                    >
                      <h1
                        style={{ fontFamily: "poppins" }}
                        className="text-white fw-extralight text-center"
                      >
                        <i className="fab fa-instagram"></i>
                        <div className="fw-extralight fs-6">@delhitandoori</div>
                        <div className="fw-light fs-5 mb-3">
                          FOLLOW US ON INSTAGRAM
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section-5 */}
      <section className=" py-1 py-lg-5 mb-3 index-section-5">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-lg-3 mb-5 col-9 mx-auto mb-lg-0">
              <div className="text-center">
                <i className="fs-1 fa fa-calendar"></i>
                <h3 style={{ fontFamily: "serif" }} className="my-3">
                  Reservations
                </h3>
                <div style={{ fontSize: "12px" }}>ARE HIGHLY RECOMMENDED</div>
              </div>
            </div>
            <div className="col-md-2 col-lg-3 mb-5 col-9 mx-auto mb-lg-0">
              <div className="text-center">
                <i className="fs-1 fa fa-car"></i>
                <h3 style={{ fontFamily: "serif" }} className="my-3">
                  Parking
                </h3>
                <div style={{ fontSize: "12px" }}>
                  VALET PARKING IS AVAILABLE
                </div>
              </div>
            </div>
            <div className="col-md-2 col-lg-3 mb-5 col-9 mx-auto mb-lg-0">
              <div className="text-center">
                <i className="fs-1 fa fa-users"></i>
                <h3 style={{ fontFamily: "serif" }} className="my-3">
                  Dress Attire
                </h3>
                <div style={{ fontSize: "12px" }}>SMART CASUAL</div>
              </div>
            </div>
            <div className="col-md-2 col-lg-3 mb-5 col-9 mx-auto mb-lg-0">
              <div className="text-center">
                <i className="fs-1 fa fa-leaf"></i>
                <h3 style={{ fontFamily: "serif" }} className="my-3">
                  Vegetarian
                </h3>
                <div style={{ fontSize: "12px" }}>VEGETARIAN ON REQUEST</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section-6 */}
      <section
        style={{ maxWidth: "1980px" }}
        className="index-section-6 mx-auto py-5"
      >
        <div className="container">
          <div className="text-center my-5" style={{ fontFamily: "serif" }}>
            <h1
              style={{ fontFamily: "serif" }}
              className="mb-0 section-heading"
            >
              Offered Menu
            </h1>
            <div>Some Trendy And Popular Courses Offered</div>
          </div>
          <div className="text-center ">
            <ul
              style={{ flexWrap: "nowrap" }}
              className="nav nav-tabs  overflow-auto  border-0 mx-auto"
            >
              {offeredMenu.map((v, i) => (
                <li
                  key={"cksd" + i}
                  className="nav-item mb-4 index-custom-tab-item"
                >
                  <button
                    onClick={(e) => {
                      if (document.body.offsetWidth > 700) e.preventDefault();
                      document
                        .querySelectorAll(".index-custom-tab-link")
                        .forEach((el) => {
                          el.classList.remove("active");
                        });

                      e.currentTarget.classList.add("active");
                      handleMenuTabClick(v.id);
                    }}
                    className={
                      "event-tab h-100 nav-link index-custom-tab-link " +
                      (i === 0 ? "active" : "")
                    }
                    aria-current="page"
                  >
                    {/* <span>Special</span>
                  <br /> */}
                    <span>{v.name}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div
              style={{ minHeight: "616px" }}
              className="row menu-tab mx-auto align-content-start"
              id="special"
            >
              <OfferedMenu indexRec={indexRec} products={productsToShow} />
            </div>
          </div>
        </div>
      </section>

      {/* section-7 */}
      <section className="pb-3 pt-lg-3 index-section-7">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 left-card text-center">
              <div style={{ fontFamily: "serif" }}>
                <h1 className="section-heading">Find Us</h1>
                <h3 className="my-3">WHERE</h3>
              </div>
              <div
                style={{ fontFamily: "arimo" }}
                className="text-secondary fs-lg-5"
              >
                Maridalsveien 4, 0178 Oslo
              </div>
              <button
                style={{ fontFamily: "arimo" }}
                className="btn index-book-table-btn my-4"
                data-bs-toggle="modal"
                data-bs-target="#bookATableModal"
              >
                BOOK A TABLE
              </button>

              <div>
                <h3 style={{ fontFamily: "serif" }} className="my-3">
                  WHEN
                </h3>
                <div
                  className="text-secondary fs-lg-5 my-3"
                  style={{ fontFamily: "arimo" }}
                >
                  Monday - Sunday{" "}
                  <small className="d-block">03 pm - 10 pm</small>
                </div>
                <div
                  className="text-secondary fs-lg-5 my-3"
                  style={{ fontFamily: "arimo" }}
                >
                  Saturday - Sunday{" "}
                  <small className="d-block">03 pm - 10 pm</small>
                </div>
              </div>
            </div>
            <div className="  col-lg-7">
              <div className="h-100" style={{ minHeight: "300px" }}>
                <iframe
                  title="location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1999.7517422824233!2d10.746318499252755!3d59.91966739892697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e64469baa1b%3A0xc56c53e8f3c27d94!2sDelhi%20Tandoori!5e0!3m2!1sen!2s!4v1712176429859!5m2!1sen!2s"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen={false}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
