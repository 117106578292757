import { Link, NavLink } from "react-router-dom";
import Logo from "./logo";

const Navbar = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.getElementById("navbarSupportedContent").classList.remove("show");
  };
  return (
    <nav
      id="navbar"
      style={{ position: "fixed", left: "0" }}
      className="navbar navbar-expand-lg  py-1 w-100 dark-navbar-bg"
      data-bs-theme="dark"
    >
      <div className="container">
        <Link className="navbar-brand" href="/">
          <Logo />
        </Link>

        <button
          style={{ boxShadow: "unset" }}
          className="navbar-toggler rounded-1"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse custom"
          id="navbarSupportedContent"
        >
          <div
            className="navbar-nav mx-auto my-4 my-lg-0"
            style={{ fontFamily: "arimo" }}
          >
            <div className="text-center">
              {window.dat &&
              window.dat.toggles &&
              parseInt(window.dat.toggles["bookings"].status) === 0 ? (
                ""
              ) : (
                <button
                  onClick={handleLinkClick}
                  className="btn btn-sm btn-outline-light book-a-table border-2 rounded-1 "
                  data-bs-toggle="modal"
                  data-bs-target="#bookATableModal"
                >
                  BOOK A TABLE
                </button>
              )}
            </div>
          </div>
          <ul
            className="navbar-nav   mb-2 mb-lg-0"
            style={{ fontFamily: "arimo" }}
          >
            <li className="nav-item">
              <NavLink
                onClick={handleLinkClick}
                className={({ isActive, isPending }) =>
                  isActive
                    ? "nav-link custom-nav-link active"
                    : "nav-link custom-nav-link"
                }
                to="/"
              >
                HOME
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                onClick={handleLinkClick}
                className={({ isActive, isPending }) =>
                  isActive
                    ? "nav-link custom-nav-link active"
                    : "nav-link custom-nav-link"
                }
                to="/menu"
              >
                MENU
              </NavLink>
            </li>
            {window.dat &&
            window.dat.toggles &&
            parseInt(window.dat.toggles["takeaway_orders"].status) === 0 ? (
              ""
            ) : (
              <li className="nav-item">
                <NavLink
                  onClick={handleLinkClick}
                  className={({ isActive, isPending }) =>
                    isActive
                      ? "nav-link custom-nav-link active"
                      : "nav-link custom-nav-link"
                  }
                  to="/take-away"
                >
                  TAKE AWAY
                </NavLink>
              </li>
            )}
            <li className="nav-item">
              <NavLink
                onClick={handleLinkClick}
                className={({ isActive, isPending }) =>
                  isActive
                    ? "nav-link custom-nav-link active"
                    : "nav-link custom-nav-link"
                }
                to="/contact-us"
              >
                CONTACT US
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
