import axios from "axios";
axios.defaults.withCredentials = true;
export const baseUrl =
  window.env && window.env.BASE_URL ? window.env.BASE_URL : "http://localhost";
const http = {
  get: async (url = "", config = {}) => {
    try {
      const response = await axios.get(baseUrl + url, { config });
      return response;
    } catch (err) {
      return err;
    }
  },
  delete: async (url = "", config = {}) => {
    try {
      const response = await axios.delete(baseUrl + url, config);
      return response;
    } catch (err) {
      return err;
    }
  },
  post: async (url = "", data = {}, config = {}) => {
    try {
      const response = await axios.post(baseUrl + url, data, config);
      return response;
    } catch (error) {
      return { error };
    }
  },
  put: async (url = "", data = {}, config = {}) => {
    try {
      const response = await axios.put(baseUrl + url, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response;
    } catch (error) {
      return { error };
    }
  },
};

export default http;
