import { useEffect, useState } from "react";
import http, { baseUrl } from "../functions/http";
import { useDispatch, useSelector } from "react-redux";
import productsServingsStore from "../store/reducers/products-servings";
import productsStore from "../store/reducers/products";
import categoriesStore from "../store/reducers/categories";

import ProductServingSelector from "./components/product-serving-selector";
import Basket from "./components/basket";

const TakeAway2 = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const productsServings = useSelector(
    (state) => state.productsServings.productsServings
  );
  const categories = useSelector((state) => state.categories.categories);

  const [selectedCategory, set_selectedCategory] = useState(0);

  useEffect(() => {
    const getProducts = async () => {
      set_selectedCategory(categories.length > 0 ? categories[0].id : 0);
      if (productsServings && products && categories.length > 1) return;
      try {
        const res = await http.get("/api/products?order_by=1&categorically=1");

        if (!products && res.data.products)
          dispatch(productsStore.load(res.data.products));

        if (!productsServings && res.data.products_servings)
          dispatch(productsServingsStore.load(res.data.products_servings));
        if (categories.length < 1 && res.data.categories)
          dispatch(categoriesStore.load(res.data.categories));
      } catch (err) {
        console.log(err);
      }
    };
    getProducts();
  }, [dispatch, categories, products, productsServings]);

  const handleCategoryClick = (category) => {
    set_selectedCategory(category.id);
  };
  if (
    window.dat &&
    window.dat.toggles &&
    parseInt(window.dat.toggles["takeaway_orders"].status) === 0
  ) {
    return (
      <div
        style={{ height: "calc(100vh - 290px)" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div>
          <h5 className=" alert alert-danger mb-0">
            Take away will be availble soon!
          </h5>
          <p className="text-danger">
            This service is temporarily not available.
          </p>
        </div>
      </div>
    );
  }
  return (
    <>
      {/* section-1 */}
      <section className="take-away-2-section-1  mt-5 py-5">
        <div className="container-fluid" style={{ maxWidth: "1990px" }}>
          <div className="row">
            {/* menu */}
            <div className="col-lg-8 col-xxl-9">
              {/* list */}
              <div className="d-flex flex-wrap take-away-nav">
                {/*  <div className="take-away-tab">Appetizer</div>
                <div className="take-away-tab">Soup</div>
                <div className="take-away-tab active">Biryani Dishes</div>
                <div className="take-away-tab">BBQ</div>
                <div className="take-away-tab">Chicken Dishes</div>
                <div className="take-away-tab">Lamb Dishes</div>
                <div className="take-away-tab">Vegetable Dishes</div>
                <div className="take-away-tab">Fish / Prawn Dishes</div>
                <div className="take-away-tab">Duck Dishes</div>
                <div className="take-away-tab">Naan Bread</div>
                <div className="take-away-tab">Raita</div>
                <div className="take-away-tab">Dessert</div>
                <div className="take-away-tab">Homemade Beverages</div>
                <div className="take-away-tab">Soft Drinks</div>
                <div className="take-away-tab">Miscellaneous</div> */}

                {categories.map((c, i) => (
                  <div
                    key={"csdf" + i}
                    onClick={(e) => {
                      document
                        .querySelector(".take-away-tab.active")
                        .classList.remove("active");
                      e.currentTarget.classList.add("active");
                      handleCategoryClick(c);
                    }}
                    className={`take-away-tab ${
                      c.id === selectedCategory ? "active" : ""
                    }`}
                  >
                    {c.name}
                  </div>
                ))}
              </div>
              {/* cards */}
              <div className="list-unstyled my-3 menu-cards custom-scroll-1 px-3 overflow-auto fw-semibold">
                <div className="row">
                  {products && products[selectedCategory]
                    ? products[selectedCategory].map((p, i) => (
                        <div
                          key={"asxse" + p.id}
                          className="col-xxl-3 col-lg-4 my-3"
                        >
                          <div className="card h-100">
                            <img
                              src={baseUrl + "/" + p.image}
                              className="card-img-top"
                              alt={p.name}
                            />
                            <div className="card-body">
                              <h5 className="card-title">{p.name}</h5>
                              <p className="card-text text-secondary">
                                {p.description}
                              </p>
                              <ProductServingSelector
                                pName={p.name}
                                productsServings={productsServings[p.id]}
                                selectedCategory={selectedCategory}
                                pId={p.id}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>

            {/* basket */}
            <div className="col-lg-4 col-xxl-3  p-0">
              <Basket />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TakeAway2;
