const _email = (value) => {
  let err = null;
  if (value === "") {
    err = "required!";
  } else if (value.length > 255) {
    err = "must not exceed 255 characters!";
  } else if (
    !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(value)
  ) {
    err = "email is not valid!";
  }

  return err ? { err } : value;
};

const _name = (value) => {
  let err = null;
  if (value === "") {
    err = "required!";
  } else if (value.length > 255) {
    err = "must not exceed 255 characters!";
  } else if (!/^[a-zA-Z-' ]*$/.test(value)) {
    err = "allowed special characters = (- ')!";
  }

  return err ? { err } : value;
};

const _phone = (value) => {
  let err = null;
  if (value === "") {
    err = "required!";
  } else if (value.length > 20) {
    err = "must not exceed 20 characters!";
  } else if (!/^\+?\d+$/.test(value)) {
    err = "invalid phone!";
  }

  return err ? { err } : value;
};
const _description = (value = "") => {
  let err = null;
  if (value.length > 1000) {
    err = "must not exceed 1000 characters!";
  }

  return err ? { err } : value.trim();
};
const validate = {
  _email,
  _name,
  _phone,
  _description,
};
export default validate;
