import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "animate.css";
import "./App.css";
import { Route } from "react-router-dom";
import Index from "./pages/Index";
import Layout from "./layout";
import Menu from "./pages/Menu";
import Reservation from "./pages/Reservation";
import ContactUs from "./pages/Contact-Us";
import TakeAway from "./pages/Take-Away";
import TakeAway2 from "./pages/Take-Away2";
import ConfirmOrder from "./pages/Confirm-Order";
import ErrorElement from "./pages/errorElement";

function App() {
  return (
    <>
      <Route path="/take-away/confirm" element={<ConfirmOrder />} />

      <Route path="/*" element={<Layout />}>
        <Route index element={<Index />} />
        <Route path="menu" element={<Menu />} />
        <Route path="take-away" element={<TakeAway />} />
        <Route path="take-away/start" element={<TakeAway2 />} />
        <Route path="reservation" element={<Reservation />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="*" element={<ErrorElement />} />
      </Route>
    </>
  );
}

export default App;
