import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cart from "../../store/reducers/cart";
import emptyBasketImage from "../../images/empty-basket.png";

const Basket = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);

  const handleRemoveFromCart = (data) => {
    dispatch(cart.remove(data));
  };
  return (
    <>
      {" "}
      <div
        onClick={() => {
          document.querySelector(".basket").classList.remove("d-none");
        }}
        className="basket-btn"
      >
        <i className="fas fa-shopping-basket"></i>
        <br />
        {cartItems.reduce((a, b) => a + b.quantity, 0)} items
      </div>
      <div className="py-3 bg-light border-top d-none d-lg-block basket ">
        <h2 className="fw-bold text-center position-relative">
          Your Basket{" "}
          <i
            onClick={() => {
              document.querySelector(".basket").classList.add("d-none");
            }}
            style={{ right: "15px", top: "-6px" }}
            className="fa fa-times d-lg-none position-absolute"
          ></i>{" "}
        </h2>
        {cartItems.length < 1 ? (
          <div className="text-center" style={{ margin: "140px 0" }}>
            <img
              src={emptyBasketImage}
              width={100}
              height={92.33}
              alt="empty basket"
            />
            <div className="fw-bold text-danger">Empty!</div>
          </div>
        ) : (
          <>
            <ul className="list-unstyled my-5 custom-scroll-2 px-3 overflow-auto fw-semibold">
              {cartItems.map((v, i) => (
                <li key={"hbvcd" + i} className=" mb-4 d-flex">
                  <div className="me-auto">
                    <div>
                      {v.pName}{" "}
                      <span
                        style={{
                          paddingBottom: "2px",
                          backgroundColor: "#CDDC39",
                        }}
                        className="px-2"
                      >
                        {v.quantity} x
                      </span>
                    </div>{" "}
                    <div
                      style={{ fontSize: ".7em" }}
                      className="text-secondary"
                    >
                      {v.psName}
                    </div>
                  </div>
                  <div>
                    kr. {v.price}{" "}
                    <i
                      onClick={() => {
                        handleRemoveFromCart({ pId: v.pId, psId: v.psId });
                      }}
                      title="remove from basket"
                      className="ms-3 fa fa-times"
                    ></i>
                  </div>
                </li>
              ))}
            </ul>
            <div className="px-3">
              {cartItems.length > 0 ? (
                <Link
                  to={"/take-away/confirm"}
                  className="btn w-100 align-items-center fw-semibold rounded-1 d-inline-flex custom-btn-green"
                >
                  <div className="px-2 rounded-1 bg-success">
                    {cartItems.reduce((a, b) => a + b.quantity, 0)} items
                  </div>{" "}
                  <div className="mx-auto">Take Away</div>
                  <div>
                    {cartItems.reduce((a, b) => a + b.price * b.quantity, 0)} kr
                  </div>
                </Link>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Basket;
