import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cart from "../../store/reducers/cart";
import chevDown from "../../images/chevron-down.png";
import { useEffect } from "react";
import { useRef } from "react";
import alert from "../../store/reducers/alert-reducer";

const ProductServingSelector = ({
  pId,
  pName,
  productsServings,
  selectedCategory,
}) => {
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();
  const selectElement = useRef();
  const [selectedServing, set_selectedServing] = useState(0);

  if (!selectElement.current) {
    selectElement.current = {};
  }
  useEffect(() => {
    set_selectedServing(productsServings[0]);
    selectElement.current.value = productsServings[0].id;
  }, [productsServings]);

  const handleAddToCart = () => {
    if (cartItems.length > 100) {
      dispatch(
        alert.load({
          message:
            "Amount of the items in the the basket has been reached to full.",
          heading: "Limit Exceeded!",
          type: "danger",
        })
      );
    }
    dispatch(
      cart.add({
        pId,
        pName,
        psId: selectedServing.id,
        psName: selectedServing.serving,
        price: selectedServing.price,
        quantity: 1,
      })
    );
  };

  return (
    <>
      <div className="d-none">{selectedCategory}</div>
      {productsServings.length > 0 && productsServings[0].serving ? (
        <select
          ref={selectElement}
          onChange={(e) => {
            const value = e.currentTarget.value;

            const productServing = productsServings.find(
              (ps) => ps.id === parseInt(value)
            );
            set_selectedServing(productServing);
          }}
          style={{ backgroundImage: `url(${chevDown})` }}
          className="form-select rounded-1 mb-3 border-secondary text-white bg-secondary"
        >
          {productsServings.map((ps, ips) => {
            return (
              <option
                key={"xvasd" + ips}
                className="bg-light text-dark"
                value={ps.id}
              >
                {ps.serving}
              </option>
            );
          })}
        </select>
      ) : (
        ""
      )}
      <button
        onClick={handleAddToCart}
        className="btn w-100 custom-btn-green py-1 rounded-1"
      >
        Add{" "}
        {selectedServing ? (
          <div className="float-end">{selectedServing.price} kr</div>
        ) : (
          ""
        )}
      </button>
    </>
  );
};

export default ProductServingSelector;
