import { Link, NavLink } from "react-router-dom";
import footerBg from "../../images/footer-bg.webp";
import logo1 from "../../images/logo1.png";
import Logo from "./logo";
import xIcon from "../../images/xicon.webp";
const Footer = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.getElementById("navbarSupportedContent").classList.remove("show");
  };
  return (
    <footer
      style={{
        backgroundImage: `url(${footerBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-5 d-flex align-items-center justify-content-center">
            <div className="py-4">
              <div
                style={{ fontSize: "9.2px" }}
                className="d-flex footer-logo flex-wrap"
              >
                <div>
                  <img
                    src={logo1}
                    style={{ width: "8em" }}
                    alt="delhi tandoori"
                    className="me-2"
                  />
                </div>
                <div style={{ fontSize: "1em" }} className="text-end">
                  <Logo noHover size={"5em"} colored />
                  <small
                    style={{ fontSize: "1em" }}
                    className="fst-italic fw-semibold"
                  >
                    Same Family - Same Taste
                  </small>
                </div>
              </div>
              <ul
                className="list-unstyled d-flex social-icons mb-0 pt-2"
                style={{ fontSize: "22px" }}
              >
                <li>
                  <a
                    title="facebook"
                    className="px-2 me-2"
                    href="https://www.facebook.com/Delhi.tandoori76"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a
                    title="instagram"
                    className="px-2 me-2"
                    href="https://www.instagram.com/delhi.tandoori?igsh=ZTI2dWQ4NDg5Z3N1"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    title="twitter"
                    className="px-2 me-2 hover-bg-white"
                    href="https://x.com/Delhi_Tandoori?t=N_92rvc7s3fSedy0F33aCg&s=09"
                  >
                    <img
                      src={xIcon}
                      width={20}
                      height={20}
                      style={{ marginBottom: "6px" }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    title="linked in"
                    className="px-2 me-2"
                    href="https://www.linkedin.com/company/delhi-tandoori-as/"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                {/* <li>
                  <a title="tik tok" className="px-2 me-2" href="/">
                    <i className="fab fa-tiktok"></i>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <ul className="footer-nav">
              <li>
                <NavLink
                  onClick={handleLinkClick}
                  className={({ isActive, isPending }) =>
                    isActive ? " active" : ""
                  }
                  to={"/"}
                >
                  <span>HOME</span>
                </NavLink>
              </li>
              {/* <li>
                <Link>
                  <span>RESERVATION</span>
                </Link>
              </li> */}
              <li>
                <NavLink
                  onClick={handleLinkClick}
                  className={({ isActive, isPending }) =>
                    isActive ? " active" : ""
                  }
                  to="/menu"
                >
                  <span>MENU</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={handleLinkClick}
                  className={({ isActive, isPending }) =>
                    isActive ? " active" : ""
                  }
                  to="/take-away"
                >
                  <span>TAKE AWAY</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={handleLinkClick}
                  className={({ isActive, isPending }) =>
                    isActive ? " active" : ""
                  }
                  to="/about"
                >
                  <span>ABOUT US</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={handleLinkClick}
                  className={({ isActive, isPending }) =>
                    isActive ? " active" : ""
                  }
                  to="/contact-us"
                >
                  <span>CONTACT US</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <div className="py-3 text-center ">
              <div className="mb-3">
                <Link
                  onClick={handleLinkClick}
                  to="/contact-us"
                  style={{ width: "150px" }}
                  className="btn btn-dark py-1  "
                >
                  CONTACT US
                </Link>
              </div>
              <div className="fw-bold mb-2">
                Address:
                <br /> Maridalsvelen 4,0178 Oslo
              </div>
              <div className="fw-bold">
                Phone:
                <br /> +47 22 20 12 48
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
