const { createSlice } = require("@reduxjs/toolkit");

const alertSlice = createSlice({
  name: "alert",
  initialState: {
    type: "",
    heading: "",
    message: "",
    detail: "",
  },
  reducers: {
    load: (state, action) => {
      state.type = action.payload.type || "";
      state.heading = action.payload.heading;
      state.message = action.payload.message;

      state.detail = action.payload.detail || "";
    },
    setType: (state, action) => {
      state.type = action.payload.type;
    },
    setHeading: (state, action) => {
      state.heading = action.payload.heading;
    },
    setMessage: (state, action) => {
      state.message = action.payload.message;
    },
    clear: (state) => {
      state.type = "";
      state.heading = "";
      state.message = "";

      state.detail = "";
    },
  },
});

const alert = alertSlice.actions;
export default alert;
export const alertReducer = alertSlice.reducer;
