import { Helmet } from "react-helmet";
import forkSteakImage from "../images/menu/fork-steak.webp";
import steakImage from "../images/menu/steak.webp";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import http from "../functions/http";
import { useDispatch, useSelector } from "react-redux";
import productsServingsStore from "../store/reducers/products-servings";
import productsStore from "../store/reducers/products";
import categoriesStore from "../store/reducers/categories";
import ArrowDown from "./components/banner-arrow-down";

const Menu = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const productsServings = useSelector(
    (state) => state.productsServings.productsServings
  );
  const categories = useSelector((state) => state.categories.categories);

  useEffect(() => {
    const getProducts = async () => {
      if (productsServings && products && categories.length > 1) return;
      try {
        const res = await http.get("/api/products?order_by=1&categorically=1");

        if (!products && res.data.products)
          dispatch(productsStore.load(res.data.products));

        if (!productsServings && res.data.products_servings)
          dispatch(productsServingsStore.load(res.data.products_servings));
        if (categories.length < 1 && res.data.categories)
          dispatch(categoriesStore.load(res.data.categories));
      } catch (err) {
        console.log(err);
      }
    };
    //getProducts();
    AOS.init();
  }, [dispatch, categories.length, products, productsServings]);

  return (
    <>
      <Helmet>
        <title>Indian Cuisine Menu | Delhi Tandoori Restaurant</title>
        <meta
          name="description"
          content="Experience exquisite Indian food at Delhi Tandoori Restaurant. Indulge in authentic flavours that showcase the rich heritage of Indian cuisine."
        />
      </Helmet>
      {/* banner */}
      <div
        className="banner-height menu-banner"
        style={{
          position: "relative",
        }}
      >
        <div className="h-100 d-flex flex-wrap flex-column justify-content-center align-items-center">
          <h1
            style={{ backgroundColor: "#000000b0" }}
            className=" text-white w-100 text-center py-lg-2 fs-2   fs-lg-3  mt-5 py-1  fw-light  "
          >
            <span className="d-inline-block animate__animated animate__tada">
              OUR MENUS
            </span>
          </h1>
        </div>
        <ArrowDown />
      </div>

      {/* section-1 */}
      {/* <section className="overflow-hidden menu-section py-5 mt-lg-5">
        <div className="container">
          <div className="row mx-auto" style={{ maxWidth: "1000px" }}>
            {categories
              .filter((category) => products[category.id])
              .map((v, i) => {
                return (
                  <div key={"sdfx" + i} className="col-md-6 mb-3">
                    <div
                      data-aos="flip-up"
                      style={{ fontFamily: "serif" }}
                      className={`shadow h-100 ${
                        (i + 1) % 2 === 0 ? "menu-border-2" : "menu-border-1"
                      } border-top p-3 px-md-4 border-7`}
                    >
                      <h1 className="fw-light mt-3 mb-4 fs-2  text-center">
                        {v.name}
                      </h1>

                      <ul className="list-unstyled fs-xxl-5 fw-light">
                        {products[v.id]
                          ? products[v.id].map((p, c) => {
                              return (
                                <li
                                  key={"dfs" + c}
                                  className="li-hover border-bottom mb-4 d-flex"
                                >
                                  <div className="me-auto fw-semibold">
                                    {p.name}
                                  </div>
                                  <table>
                                    <tbody>
                                      {productsServings[p.id].map((e, k) => (
                                        <tr key={"ngfgh" + k}>
                                          <td>{e.serving}</td>
                                          <td className="px-4"> = </td>
                                          <td>kr. {e.price}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </li>
                              );
                            })
                          : ""}
                      </ul>
                    </div>
                  </div>
                );
              })}
              <div className="col-md-6 mb-3">
              <div
                data-aos="flip-up"
                style={{ fontFamily: "serif" }}
                className="shadow menu-border-2 border-top p-3 px-md-4 border-7"
              >
                <h1 className="fw-light mt-3 mb-4 fs-2  text-center">SOUP</h1>

                <ul className="list-unstyled fs-xxl-5 fw-light">
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                </ul>
              </div>
            </div> 
          </div>

          <div className="row my-5">
            <div className="col-md-4 mb-3">
              <img
                src={forkSteakImage}
                className="w-100"
                style={{ height: "500px", objectFit: "cover" }}
                alt="fork-steak"
              />
            </div>
            <div className="col-md-8 mb-3">
              <img
                src={steakImage}
                className="w-100"
                style={{ height: "500px", objectFit: "cover" }}
                alt="steak"
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* section-2 */}
      <section className="overflow-hidden menu-section pb-5 pt-4">
        <div className="container">
          <div className="row mx-auto" style={{ maxWidth: "1000px" }}>
            {categories
              .filter((category) => products[category.id])
              .map((v, i) => {
                return (
                  <div key={"sdfx" + i} className="col-md-6 mb-3">
                    <div
                      data-aos="flip-up"
                      style={{ fontFamily: "serif" }}
                      className={`shadow h-100 ${
                        (i + 1) % 2 === 0 ? "menu-border-2" : "menu-border-1"
                      } border-top p-3 px-md-4 border-7`}
                    >
                      <h1 className="fw-light mt-3 mb-4 fs-2  text-center">
                        {v.name}
                      </h1>

                      <ul className="list-unstyled fs-xxl-5 fw-light">
                        {products[v.id]
                          ? products[v.id].map((p, c) => {
                              let price = 0;
                              return (
                                <li
                                  key={"dfs" + c}
                                  className="li-hover mb-4 d-flex"
                                >
                                  <div className="me-auto">
                                    {p.name}
                                    {productsServings[p.id] &&
                                    productsServings[p.id].length > 0 ? (
                                      <span>
                                        {" ("}
                                        {productsServings[p.id].map((e, k) => {
                                          if (k === 0) price = e.price;
                                          return (
                                            e.serving +
                                            (productsServings[p.id].length - 1 >
                                            k
                                              ? ", "
                                              : "")
                                          );
                                        })}
                                        {")"}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    style={{ minWidth: "76px" }}
                                    className="text-end"
                                  >
                                    kr. {price}
                                  </div>
                                </li>
                              );
                            })
                          : ""}
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="row my-5">
            <div className="col-md-8 mb-3">
              <img
                src={steakImage}
                className="w-100"
                style={{ height: "500px", objectFit: "cover" }}
                alt="steak"
              />
            </div>
            <div className="col-md-4 mb-3">
              <img
                src={forkSteakImage}
                className="w-100"
                style={{ height: "500px", objectFit: "cover" }}
                alt="fork-steak"
              />
            </div>
          </div>
        </div>
      </section>

      {/* section-3 */}
      {/* <section className="overflow-hidden menu-section  pb-5 ">
        <div className="container">
          <div className="row my-5">
            <div className="col-md-8 mb-3">
              <img
                src={steakImage}
                className="w-100"
                style={{ height: "500px", objectFit: "cover" }}
                alt="steak"
              />
            </div>
            <div className="col-md-4 mb-3">
              <img
                src={forkSteakImage}
                className="w-100"
                style={{ height: "500px", objectFit: "cover" }}
                alt="fork-steak"
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* section-4 */}
      {/* <section className="overflow-hidden menu-section  pb-5 mb-5">
        <div className="container">
          <div className="row mx-auto" style={{ maxWidth: "1000px" }}>
            <div className="col-md-6 mb-3">
              <div
                data-aos="flip-up"
                style={{ fontFamily: "serif" }}
                className="shadow h-100 menu-border-1 bg-dark text-light border-top p-3 px-md-4 border-7"
              >
                <h1 className="fw-light mt-3 mb-4 fs-2  text-center">
                  SWEET DISHES
                </h1>

                <ul className="list-unstyled fs-xxl-5 fw-light">
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                  <li className="li-hover mb-4 d-flex">
                    <div className="me-auto">Aloo Tikki {"(2,5,3,7)"}</div>
                    <div>kr. 64</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <img
                src={sweetDishImage}
                className="w-100 h-100"
                style={{
                  minheight: "500px",
                  maxHeight: "650px",
                  objectFit: "cover",
                }}
                alt="steak"
              />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Menu;
