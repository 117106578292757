import { Outlet } from "react-router-dom";
import Header from "./pages/components/header";
import Navbar from "./pages/components/navbar";
import Footer from "./pages/components/footer";
import BookATableModal from "./pages/components/book-a-table-modal";
import Alert from "./pages/components/alert";

import WaitModal from "./pages/components/wait-modal";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
const Layout = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Helmet>
        <title>Delhi Tandoori | Exquisite Indian Restaurant</title>
        <meta
          name="description"
          content="Experience India's authentic flavours at Delhi Tandoori, your top Indian restaurant in Oslo. Indulge in tandoori delights today!"
        />
      </Helmet>
      <Alert fixed={true} />
      <WaitModal />
      <BookATableModal />

      <Navbar />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
