import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: { items: [] },
  reducers: {
    add: (state, action) => {
      const prevItem = state.items.find((v) => {
        return v.pId === action.payload.pId && v.psId === action.payload.psId;
      });

      if (prevItem) prevItem.quantity += 1;
      else state.items.push(action.payload);
    },
    remove: (state, action) => {
      const prevItem = state.items.find((v) => {
        return v.pId === action.payload.pId && v.psId === action.payload.psId;
      });

      if (prevItem.quantity > 1) prevItem.quantity -= 1;
      else
        state.items = state.items.filter(
          (v) => v.pId !== action.payload.pId || v.psId !== action.payload.psId
        );
    },
    clear(state) {
      state.items = [];
    },
  },
});

const cart = cartSlice.actions;
export default cart;
export const cartReducer = cartSlice.reducer;
