const { createSlice } = require("@reduxjs/toolkit");

const productsServingsSlice = createSlice({
  name: "productsServings",
  initialState: {
    productsServings:
      window.dat && window.dat.products_servings
        ? window.dat.products_servings
        : 0,
  },
  reducers: {
    load: (state, action) => {
      state.productsServings = action.payload;
    },

    clear: (state) => {
      state.productsServings = 0;
    },
  },
});

const productsServingsStore = productsServingsSlice.actions;
export default productsServingsStore;
export const productsServingsReducer = productsServingsSlice.reducer;
