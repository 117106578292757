import { Link } from "react-router-dom";
import bookingBannerImage from "../images/booking-banner.webp";
import { Helmet } from "react-helmet";
const TakeAway = () => {
  if (
    window.dat &&
    window.dat.toggles &&
    parseInt(window.dat.toggles["takeaway_orders"].status) === 0
  ) {
    return (
      <>
        <Helmet>
          <title>
            Indian Restaurant Takeaway | Delhi Tandoori - Order To Go
          </title>
          <meta
            name="description"
            content="Enjoy our authentic Indian restaurant takeaway service from Delhi Tandoori. Savor the flavours of India wherever you are. Order now for a delightful experience."
          />
        </Helmet>
        <div
          style={{ height: "calc(100vh - 290px)" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <h5 className=" alert alert-danger mb-0">
              Take away will be availble soon!
            </h5>
            <p className="text-danger">
              This service is temporarily not available.
            </p>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>Indian Restaurant Takeaway | Delhi Tandoori - Order To Go</title>
        <meta
          name="description"
          content="Enjoy our authentic Indian restaurant takeaway service from Delhi Tandoori. Savor the flavours of India wherever you are. Order now for a delightful experience."
        />
      </Helmet>
      {/* section-1 */}
      <section className="take-away-1-section-1  mt-5 py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6  ">
              <img
                style={{ objectFit: "cover" }}
                className="img-fluid shadow h-100"
                src={bookingBannerImage}
                width={1080}
                height={720}
                alt="delhi tandoori booking"
              />
            </div>
            <div className="col-lg-6 py-5 text-center">
              <h2 className="fw-bold " style={{ fontFamily: "serif" }}>
                Delhi Tandoori Take Away and Restaurant,{" "}
                <span className="text-danger">Oslo</span>
              </h2>
              <div
                style={{ fontFamily: "poppins" }}
                className="p-4 my-4 bg-light  light"
              >
                Collecting from
                <br /> Oslo
                <br />
                Maridalsveien 4,0178
              </div>
              <div>
                <Link
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    document
                      .getElementById("navbarSupportedContent")
                      .classList.remove("show");
                  }}
                  to={"start"}
                  className="btn w-100 custom-btn-green"
                >
                  Start my order <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section-2 */}
      <section>
        <div className="container">
          <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1999.7517422824233!2d10.746318499252755!3d59.91966739892697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e64469baa1b%3A0xc56c53e8f3c27d94!2sDelhi%20Tandoori!5e0!3m2!1sen!2s!4v1712176429859!5m2!1sen!2s"
            width="100%"
            height="400px"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      {/* section-3 */}
      <section className="index-section-4 py-5 my-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div>
                <div className="position-relative mb-4 w-100 card-bg-1">
                  <div style={{ width: "100%", paddingTop: "90%" }}>
                    <div
                      style={{ zIndex: "3" }}
                      className="position-absolute d-flex align-items-center justify-content-center top-0 start-0 h-100 w-100"
                    >
                      <h1
                        style={{ fontFamily: "poppins" }}
                        className="text-white fw-extralight text-center"
                      >
                        <i
                          style={{ fontSize: "1.5em" }}
                          className="fas fa-glass-martini-alt"
                        ></i>
                        <div
                          style={{ fontFamily: "serif" }}
                          className="fst-italic"
                        >
                          15% Discount
                        </div>
                        <div>HAPPY HOUR</div>
                        <div className="fw-light fs-5 text-decoration-underline">
                          16:00 To 17:00
                        </div>
                        <div className="fw-light fs-4">Monday To Friday</div>
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="position-relative mb-4 mb-lg-0 w-100 bg-light-grey">
                  <div style={{ width: "100%", paddingTop: "90%" }}>
                    <div
                      style={{ zIndex: "3" }}
                      className="position-absolute d-flex align-items-center justify-content-center top-0 start-0 h-100 w-100"
                    >
                      <h1
                        style={{ fontFamily: "poppins" }}
                        className="text-center"
                      >
                        <div className="fw-light fs-6">WHAT'S HAPPENING</div>
                        <div className="fs-2" style={{ fontFamily: "serif" }}>
                          Latest News, Stories And <br /> Recipes
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mb-4 mb-lg-0">
              <div className="position-relative h-100 w-100  card-bg-2">
                <div style={{ width: "100%", paddingTop: "100%" }}>
                  <div
                    style={{ zIndex: "3" }}
                    className="position-absolute d-flex align-items-center justify-content-center top-0 start-0 h-100 w-100"
                  >
                    <h1
                      style={{ fontFamily: "poppins" }}
                      className="text-white fw-extralight text-center"
                    >
                      <div className="fw-light fs-6">
                        THE SOUL KITCHEN EXPERIENCE
                      </div>
                      <div
                        style={{ fontFamily: "serif" }}
                        className="fst-italic"
                      >
                        FULL-SERVING <br />
                        CATERING
                      </div>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <div className="position-relative mb-4  w-100 bg-light-grey">
                  <div style={{ width: "100%", paddingTop: "90%" }}>
                    <div
                      style={{ zIndex: "3" }}
                      className="position-absolute d-flex align-items-center justify-content-center top-0 start-0 h-100 w-100"
                    >
                      <h1
                        style={{ fontFamily: "poppins" }}
                        className="text-center"
                      >
                        <div className="fw-light fs-6">
                          THE PERFECT GIFT FOR A LOVED ONE
                        </div>
                        <div style={{ fontFamily: "serif" }}>
                          Soul Kitchen <br /> Gift Card
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="position-relative mb-4 mb-lg-0 w-100  card-bg-3">
                  <div style={{ width: "100%", paddingTop: "90%" }}>
                    <div
                      style={{ zIndex: "3" }}
                      className="position-absolute d-flex align-items-end justify-content-center top-0 start-0 h-100 w-100"
                    >
                      <h1
                        style={{ fontFamily: "poppins" }}
                        className="text-white fw-extralight text-center"
                      >
                        <i className="fab fa-instagram"></i>
                        <div className="fw-extralight fs-6">@delhitandoori</div>
                        <div className="fw-light fs-5 mb-3">
                          FOLLOW US ON INSTAGRAM
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TakeAway;
