import { useEffect, useRef, useState } from "react";

const OfferedMenu = ({ products = [], indexRec }) => {
  let timeInterval = useRef();
  let start = products[0] ? indexRec.current[products[0].category_id].start : 0;
  let end = products[0] ? indexRec.current[products[0].category_id].end : 0;
  const [productsToShowf, set_products] = useState([]);
  useEffect(() => {
    if (clearTimeout(timeInterval.current)) {
      clearTimeout(timeInterval.current);
    }
    if (products.length < 1) {
      return;
    }

    set_products([...products.slice(start, end)]);
  }, [products]);
  useEffect(() => {
    if (products[0] && products.length > 6) {
      timeInterval.current = setTimeout(() => {
        indexRec.current[products[0].category_id].start += 6;
        indexRec.current[products[0].category_id].end += 6;

        if (productsToShowf.length < 6 || !products[end]) {
          indexRec.current[products[0].category_id].start = 0;
          indexRec.current[products[0].category_id].end = 6;
        }
        set_products(
          products.slice(
            indexRec.current[products[0].category_id].start,
            indexRec.current[products[0].category_id].end
          )
        );
      }, 3000);
    }
  }, [productsToShowf]);
  if (!products[0]) return <></>;
  return (
    <>
      {productsToShowf.map((product, page) => (
        <div key={page + "xcmei"} className="col-lg-4 px-0">
          <div
            key={"cxd" + page}
            data-aos="zoom-in"
            style={{
              backgroundImage: `url(${
                window.env && window.env.BASE_URL
                  ? window.env.BASE_URL
                  : "http://localhost"
              }/${product.image})`,
            }}
            className="position-relative menu-card w-100 bg-dark"
          >
            <div style={{ width: "100%", paddingTop: "70%" }}>
              <div
                style={{ zIndex: "3" }}
                className="position-absolute d-flex align-items-end top-0 start-0 h-100 w-100"
              >
                <h2
                  style={{ fontFamily: "poppins", letterSpacing: "3px" }}
                  className="text-white px-5 py-3"
                >
                  {product.name}
                </h2>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
  let page = 1;
  const pageSize = 2;
  let pageEnd, pageStart;

  const maxPage = Math.ceil(products.length / pageSize);
  let content = [];

  while (page <= maxPage) {
    pageEnd = page * pageSize - 1;
    pageStart = pageEnd - pageSize + 1;
    const pro = [];

    for (let i = pageStart; i <= pageEnd; i++) {
      const product = products[i];
      if (!product) break;
      pro.push(
        <div
          key={"cxd" + page + i}
          data-aos="zoom-in"
          style={{
            backgroundImage: `url(${
              window.env && window.env.BASE_URL
                ? window.env.BASE_URL
                : "http://localhost"
            }/${product.image})`,
          }}
          className="position-relative menu-card w-100 bg-dark"
        >
          <div style={{ width: "100%", paddingTop: "70%" }}>
            <div
              style={{ zIndex: "3" }}
              className="position-absolute d-flex align-items-end top-0 start-0 h-100 w-100"
            >
              <h2
                style={{ fontFamily: "poppins", letterSpacing: "3px" }}
                className="text-white px-5 py-3"
              >
                {product.name}
              </h2>
            </div>
          </div>
        </div>
      );
    }
    content.push(
      <div key={page + "xcmei"} className="col-lg-4 px-0">
        {pro}
      </div>
    );
    page++;
  }
  return <>{content}</>;
};

export default OfferedMenu;
