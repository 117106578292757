import WaitSpinner from "./wait-spinner";

import { useSelector } from "react-redux";
const WaitModal = () => {
  const { show } = useSelector((store) => store.waitModal);
  return show ? (
    <div
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        top: "0",
        left: "0",
        zIndex: "2525",
        backgroundColor: "#0000004f",
      }}
    >
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        <WaitSpinner dark={true} />
      </div>
    </div>
  ) : (
    ""
  );
};

export default WaitModal;
