const ArrowDown = () => (
  <>
    <a
      id="downbtn"
      href="#downbtn"
      style={{ position: "absolute", bottom: "60px" }}
      className="start-50 translate-middle-x"
    >
      <span
        style={{
          backgroundColor: "var(--color-2)",
          padding: "9.5px 11px",
          fontSize: "24px",
          width: "43px",
          height: "43px",
          display: "inline-block",
        }}
        className="fa  rounded-circle   text-white fa-arrow-down slide-btn-animation"
      ></span>
    </a>
  </>
);

export default ArrowDown;
