import { cartReducer } from "./reducers/cart";
import { categoriesReducer } from "./reducers/categories";
import { productsReducer } from "./reducers/products";
import { productsServingsReducer } from "./reducers/products-servings";

const { configureStore } = require("@reduxjs/toolkit");
const { alertReducer } = require("./reducers/alert-reducer");
const { waitModalReducer } = require("./reducers/waitModal-reducer");
const store = configureStore({
  reducer: {
    cart: cartReducer,
    products: productsReducer,
    productsServings: productsServingsReducer,
    categories: categoriesReducer,
    alert: alertReducer,
    waitModal: waitModalReducer,
  },
});

export default store;
