const { createSlice } = require("@reduxjs/toolkit");

const waitModalSlice = createSlice({
  name: "waitModal",
  initialState: {
    show: false,
  },
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload.show;
    },
  },
});

const waitModal = waitModalSlice.actions;
export default waitModal;
export const waitModalReducer = waitModalSlice.reducer;
