const Logo = ({ size, colored, noHover }) => (
  <div
    className={`text-logo ${noHover ? "no-hover" : ""}`}
    style={{
      fontFamily: "serif",
      fontWeight: "bold",
      fontSize: size || "25px",
    }}
  >
    <div
      style={{
        lineHeight: "0.8em",
        ...(colored ? { color: "var(--color-1)" } : ""),
      }}
    >
      DELHI
    </div>
    <div
      style={{
        fontSize: "0.59em",
        lineHeight: "0.8em",
        ...(colored ? { color: "var(--color-2)" } : ""),
      }}
    >
      TANDOORI
    </div>
  </div>
);

export default Logo;
