const Spinner = ({ color, text }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="spinner-border me-2"
          style={{ color: color || "grey", borderWidth: "2px" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
        <small>{text}</small>
      </div>
    </>
  );
};

export default Spinner;
