const { createSlice } = require("@reduxjs/toolkit");

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    categories:
      window.dat && window.dat.categories ? window.dat.categories : [],
    offeredMenu:
      window.dat && window.dat.offered_menu ? window.dat.offered_menu : [],
  },
  reducers: {
    load: (state, action) => {
      state.categories = action.payload;
      const homeCategories = action.payload
        .filter((v) => v.home !== 0)
        .sort((a, b) => a.home - b.home);
      state.offeredMenu = homeCategories;
    },

    clear: (state) => {
      state.categories = [];
    },
  },
});

const categories = categoriesSlice.actions;
export default categories;
export const categoriesReducer = categoriesSlice.reducer;
